<template>
  <div ref="content">
    <img :src="activeItem.img" alt="" class="banner" />
    <div class="inner" style="padding-bottom: 0">
      <Tabs :list="tags" :activeTag="activeTag" @tabChoose="tabChoose" />
    </div>
    <!-- 品牌介绍 -->
    <div class="inner--grey">
      <div class="introduce" id="introduce">
        <img src="@/assets/brand/ydl.png" alt="" class="img" />
        <div class="info">
          <strong class="name">原动利门店</strong>
          是恒亿集团旗下实体服务窗口，依托集团资源优势，通过财商教育、保险、基金、家族办公室、投资管理等多个板块，打造“科技金融、服务为民”的新型智慧型门店。旨在将正确的金融理念，丰富的金融产品以及优质的金融服务带给每一位客户，真正实现让“金融服务生活”。
        </div>
      </div>
    </div>
    <!-- 品牌优势 -->
    <div class="inner--grey">
      <div class="value" id="value">
        <div class="title">品牌优势</div>
        <div class="list">
          <img src="@/assets/brand/advantage.png" alt="">
          <!-- <div class="list__item" v-for="(item, index) in valueList" :key="index">
            <img :src="item.img" alt="" />
            <div class="list__name">{{ item.title }}</div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 加盟流程 -->
    <div class="inner--grey">
      <div class="process" id="process">
        <div class="title">加盟流程</div>
        <img src="@/assets/brand/process1.png" alt="" class="process1" />
        <img src="@/assets/brand/process2.png" alt="" class="process2" />
        <div class="bg">
          <div class="title">一个月即可开业，成功圈定当地市场</div>
        </div>
      </div>
    </div>
    <!-- 门店展示 -->
    <div class="inner">
      <div class="exhibit" id="exhibit">
        <div class="title">门店展示</div>
        <div class="list">
          <!-- <div class="list__item" v-for="(item, index) in exhibitList" :key="index">
            <img :src="item.img" alt="" />
            <div class="list__title">{{ item.title }}</div>
          </div> -->
          <img class="map" src="@/assets/brand/store-map.png" alt="">
          <div class="store">
            <div class="store__addr" v-for="item in storeAddr" :key="item.addr">
              <div class="store__addr__province">{{item.province}}</div>
              <div class="store__addr__store">
                <div class="store__addr__store__name" v-for="subitem in item.storeList" :key="subitem">{{subitem}}</div>
              </div>
            </div>
            <div class="store__tips">
              <img src="@/assets/brand/ellipsis.png" alt="" class="store__tips__img" />
              <span class="store__tips__text">等全国201家门店</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 加盟热线 -->
    <div class="phone" id="phone">
      <div class="phone__item">
        <img src="@/assets/brand/phoneIcon.png" alt="" class="phone__icon" />
        <div class="phone__info">{{ companyInfo.phone }}</div>
      </div>
      <div class="phone__item">
        <div class="phone__name">加盟热线</div>
        <img src="@/assets/brand/hot.png" alt="" class="phone__hot" />
      </div>
    </div>
    <!-- SideBar -->
    <SideBar />
  </div>
</template>

<script>
import Tabs from '@/components/Tab.vue'
import SideBar from '@/components/SideBar.vue'
export default {
  components: {
    Tabs,
    SideBar,
  },
  data() {
    return {
      data: {},
      tags: [],
      activeTag: '',
      exhibitList: [
        {
          title: '恒大绿洲店',
          img: require('@/assets/brand/exhibit1.png'),
        },
        {
          title: '武汉御景城店',
          img: require('@/assets/brand/exhibit2.png'),
        },
      ],
      valueList: [
        {
          title: '智慧门店 沉浸体验',
          img: require('@/assets/brand/value1.png'),
        },
        {
          title: '客户需求 精准定位',
          img: require('@/assets/brand/value2.png'),
        },
        {
          title: '产品齐全 私人订制',
          img: require('@/assets/brand/value3.png'),
        },
        {
          title: '专业运营 全程服务',
          img: require('@/assets/brand/value4.png'),
        },
        {
          title: '综合配置 财富自由',
          img: require('@/assets/brand/value5.png'),
        },
      ],
      topList: {},
      storeAddr: [
        {
          province: '山西省',
          storeList: [
            '山西长治襄垣',
            '山西太原恒大绿洲',
            '兴县环城东路店',
            '柳林清河东路店',
            '广灵新城店',
            '稷山稷峰镇店',
            '侯马旺角新苑',
            '古交腾飞路店',
            '长治佳美绿洲店',
            '绛县厢城东街店',
            '长治万豪大厦店',
            '新绛汾河中学店',
            '石楼瑞金街店',
            '交城利民路店',
            '曲沃康宁街店',
            '榆社体育巷店',
            '运城寺家卓村店',
            '太原泛华森林半岛店',
            '忻州奥莱广场店',
            '夏县东风东街店',
            '运城工农西街店',
            '清徐湖东三街店',
            '长治演武小区店',
            '运城复旦大道店',
            '闻喜兴闻东街店',
            '寿阳受川南路店',
            '临汾安宇花苑店',
            '吕梁中阳店',
            '运城圣惠桥店',
            '曲沃里村镇店',
            '太原万柏林店',
            '运城禹都店',
            '静乐碾河大街',
            '晋城钟家庄店',
            '晋城苑北路店',
            '运城解放北路',
            '运城汤里村店',
            '运城豪德广场',
            '运城东孙坞村',
            '榆社凤林西街',
            '原平京原路店',
            '运城条山东街',
            '运城辛曹村店',
            '运城学苑北路',
            '运城军屯村店',
            '运城建东巷店',
            '运城解州店',
            '运城王范乡店',
            '运城乔家巷店',
            '运城永济店',
            '运城陶朱公街',
            '运城鑫义达',
            '运城紫荆花店',
            '离石锦绣店',
            '岚县岚州花园',
            '祁县金豆豆店',
            '运城芮城店',
            '运城垣曲店',
            '翼城步行街店',
            '文水凤凰路店',
            '夏县水头镇店',
            '中阳金荣路店',
            '长治紫金东街店',
            '晋城泽州鼎泽店',
            '稷山县西社镇店',
            '稷山县化峪镇店',
            '临汾福利巷店',
            '晋城泽州杨洼社区店',
            '晋城中原街店',
            '稷山蔡村乡店',
            '临汾迎宾路店',
            '晋城兰花路店',
            '晋城北石镇店',
            '临汾新百汇店',
            '晋城市南大街店',
            '晋城市豪德广场店',
            '晋城北环路店',
            '晋城泽州前程店',
            '长治武乡丰州路店',
            '高平神龙北路店',
            '交口惠泽园店',
            '绵阳市涪城店',
            '孝义西关购物广场店',
            '吕梁金亿店',
            '阳泉矿区探知店',
            '孝义祥云店',
            '忻州恒大华府店',
            '太原千禧锦绣苑店',
          ],
        },
        {
          province: '江西省',
          storeList: [
            '赣州唐江店',
            '南昌昌南明珠店',
            '南昌百万家族店',
            '南昌昌北青秀城店',
            '南昌多多保门店',
            '南昌湾里壹加壹门店',
            '南昌高新火炬店',
            '南昌莲塘店',
            '南昌洪城时代广场店',
            '南昌东湖区臻晨店',
            '南昌朝阳旗舰店',
            '赣州市赣县嘉本店',
            '吉安市吉州凯泰店',
            '南昌市熙彩店',
            '宜春市亮诺店',
            '吉安市新干县畅行店',
            '上饶市顺欣店',
          ],
        },
        {
          province: '四川省',
          storeList: [
            '成都凯旋南城店',
            '德阳德大店',
            '隆昌七彩园店',
            '绵阳桃园小区店',
            '绵阳市丰谷店',
            '德阳市旌阳店',
            '遂宁星云保店',
            '遂宁市大英县店',
            '宜宾健安鼎盛文化店',
            '遂宁国颐赢喜店',
            '泸州市楠枫店',
            '江油市新睿咨询店',
            '绵阳市泓睿店',
          ],
        },
        {
          province: '湖北省',
          storeList: [
            '湖北武汉御璟城',
            '湖北武汉凯信大厦',
            '武汉铂金天地店',
            '黄冈市蕲春漕河店',
            '黄冈市红安银座店',
            '武汉黄陂巢上城店',
            '武汉阳逻店',
          ],
        },
        {
          province: '河南省',
          storeList: [
            '周口市沈丘店',
            '焦作博爱县海燕店',
            '焦作大丰商城店',
            '开封金悦湾店',
            '许昌市禹州嘉程店',
            '焦作沁阳怀庆店',
          ],
        },

        {
          province: '山东省',
          storeList: [
            '烟台海棠路店',
            '临沂IEC国际店',
            '烟台钰翔店',
            '烟台莱阳五龙南路店',
            '烟台福海路店',
            '烟台市莱阳昌山路店',
          ],
        },
        {
          province: '安徽省',
          storeList: [
            '合肥财富广场店',
            '合肥鼎流家办店',
            '合肥诚泽财智店',
            '合肥墨辰家办店',
          ],
        },

        {
          province: '江苏省',
          storeList: [
            '常熟新世纪世茂店',
            '张家港财富大厦店',
            '苏州苏小保店',
            '无锡传家有道店',
          ],
        },

        {
          province: '湖南省',
          storeList: ['湖南湘阴锦欣店'],
        },
        {
          province: '上海市',
          storeList: ['上海中融店', '上海宝山义呈店'],
        },

        {
          province: '新疆维吾尔自治区',
          storeList: ['喀什市东城区华晨店'],
        },
        {
          province: '福建省',
          storeList: ['福州文德传承店'],
        },
        {
          province: '甘肃省',
          storeList: ['兰州中海凯旋门店'],
        },
        {
          province: '广东省',
          storeList: ['广州市越秀机山店'],
        },
        {
          province: '宁夏回族自治区',
          storeList: ['银川阅海湾店'],
        },
      ],
    }
  },
  created() {},
  mounted() {
    window.scrollTo(0, 0)
    this.getTab()
    // 监听
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 获取tab
    getTab() {
      const initMenus = this.$store.state.menus
      const currentNav = this.$store.state.navUrl
      if (initMenus.length > 0 && currentNav) {
        let arr = []
        const childList = initMenus.find((item) => item.path === currentNav)
        if (childList.child) {
          childList.child.forEach((item) => {
            item.alias = item.path.split('/')[2]
            // 跳转锚点
            if (item.path == this.$route.path) {
              setTimeout(() => {
                this.tabChoose(item)
              }, 100)
            }
            arr.push(item)
          })
        }
        this.tags = arr
        this.activeTag = arr[0].name
      }
    },
    // 选择tab
    tabChoose(item) {
      this.activeTag = item.name
      var anchor = document.getElementById(item.alias)
      anchor.scrollIntoView({ behavior: 'smooth' })
    },
    handleScroll() {
      if (!document.getElementById('introduce')) {
        return
      }
      // 记录各元素位置
      this.topList.introduce =
        document.getElementById('introduce').offsetTop + 100
      this.topList.value = document.getElementById('value').offsetTop + 100
      this.topList.process = document.getElementById('process').offsetTop + 100
      this.topList.exhibit = document.getElementById('exhibit').offsetTop + 100
      this.topList.phone = document.getElementById('phone').offsetTop + 100
      if (this.topList.introduce > window.pageYOffset) {
        this.activeTag = this.tags[0].name
      } else if (this.topList.value > window.pageYOffset) {
        this.activeTag = this.tags[1].name
      } else if (this.topList.process > window.pageYOffset) {
        this.activeTag = this.tags[2].name
      } else if (this.topList.exhibit > window.pageYOffset) {
        this.activeTag = this.tags[3].name
      } else {
        this.activeTag = this.tags[4].name
      }
    },
  },
  computed: {
    companyInfo() {
      return this.$store.state.companyInfo
    },
    activeItem() {
      return this.$store.state.activeItem
    },
  },
  watch: {
    '$store.state.menus'() {
      this.getTab()
    },
    $route(to) {
      this.tags.forEach((item) => {
        if (item.path == to.path) {
          this.tabChoose(item)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.banner {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.86rem;
}
.inner {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0.92rem 1.1rem 1.03rem;
}
.inner--grey {
  background: #f9fbfc;
}
.introduce {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0.92rem 1.1rem 1.03rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .img {
    width: 5.57rem;
  }
  .info {
    width: 10.3rem;
    font-size: 0.18rem;
    font-family: PingFang SC-Bold, PingFang SC;
    color: #555555;
    line-height: 0.47rem;
    .name {
      font-size: 0.26rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #000;
    }
  }
}
.value {
  width: 100%;
  height: 9rem;
  text-align: center;
  background-image: url('~@/assets/brand/advantage-bg.png');
  background-size: 100%;
  .title {
    font-size: 0.32rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-top: 0.79rem;
  }
  .list {
    max-width: 1440px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0.53rem auto 0;
    padding: 0 1.1rem;
    box-sizing: border-box;
    img {
      width: 100%;
      margin-bottom: 1.03rem;
    }
    .list__item {
      cursor: pointer;
      margin-top: 0.6rem;
      width: 3.7rem;
      box-shadow: 0.01rem 0.01rem 0.07rem 0.03rem rgba(124, 124, 124, 0.07);
      border-radius: 0.05rem 0.05rem 0.05rem 0.05rem;
      img {
        width: 3.7rem;
      }
    }
    .list__name {
      padding: 0.4rem 0;
      font-size: 0.19rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #555555;
    }
  }
}
.process {
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 1.1rem;
  .title {
    font-size: 0.32rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-top: 0;
  }
  .process1 {
    width: 10.81rem;
    margin-top: 0.47rem;
    display: block;
    margin: 0.47rem auto;
  }
  .process2 {
    padding: 0 0.92rem;
    box-sizing: border-box;
    display: block;
    margin-top: 0.47rem;
    max-width: 1440px;
    margin: 0 auto 0.65rem;
  }
  .bg {
    width: 100vw;
    height: 2.8rem;
    background-image: url('../../assets/brand/process3.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .title {
      padding: 0 0.92rem;
      box-sizing: border-box;
      max-width: 1440px;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: left;
      font-size: 0.45rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-decoration: underline;
      text-underline-offset: 0.1rem;
    }
  }
}
.exhibit {
  // text-align: center;
  .title {
    font-size: 0.32rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-top: 0.53rem;
    text-align: center;
  }
  .list {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-top: 0.68rem;
    .map {
      width: 7.76rem;
      height: 4.11rem;
      margin: 0 0.52rem 0 0px;
    }
    .store {
      &__addr {
        &__province {
          font-size: 0.21rem;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #f3a736;
          line-height: 0.25rempx;
          letter-spacing: 1px;
          margin: 0 0 0.15rem 0;
        }
        &__store {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.21rem;
          &__name {
            width: 20%;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            font-size: 0.16rem;
            line-height: 0.27rem;
            letter-spacing: 1px;
            margin-bottom: 0.07rem;
          }
        }
      }
      &__tips {
        display: flex;
        align-items: center;
        margin: 50px 0 80px 0;
        &__img {
          width: 28px;
          height: 6px;
        }
        &__text {
          font-size: 24px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 28px;
          letter-spacing: 1px;
          padding-left: 10px;
        }
      }
    }
    // .list__item {
    //   margin-top: 0.53rem;
    //   cursor: pointer;
    //   width: 7.09rem;
    //   background: #ffffff;
    //   box-shadow: 0.01rem 0.01rem 0.07rem 0.03rem rgba(124, 124, 124, 0.07);
    //   border-radius: 0.05rem 0.05rem 0.05rem 0.05rem;
    //   img {
    //     width: 7.09rem;
    //   }
    //   .list__title {
    //     font-size: 0.21rem;
    //     font-family: PingFang SC-Semibold, PingFang SC;
    //     font-weight: 600;
    //     color: #479ee3;
    //     padding: 0.4rem 0;
    //   }
    // }
  }
}
.phone {
  width: 100vw;
  height: 2.37rem;
  line-height: 3.37rme;
  background-image: url('../../assets/brand/phone.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 1rem;
  .phone__item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.44rem;
    .phone__icon {
      width: 0.31rem;
      height: 0.32rem;
    }
    .phone__hot {
      width: 0.24rem;
      height: 0.32rem;
    }
    .phone__info {
      font-size: 0.53rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      margin-left: 0.1rem;
    }
    .phone__name {
      font-size: 0.32rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      margin-right: 0.05rem;
    }
  }
}
</style>