<template>
  <div class="tabs">
    <div v-for="(item, index) in list" :key="index" class="tabs__item" :class="{ 'tabs__item--active': activeTag == item.name }" @click="choose(item)">
      <div class="tabs__name" :class="{ 'tabs__name--active': activeTag == item.name }"> {{ item.name }}</div>
      <img v-if="showTri" class="tabs__img" :src="activeTag==item.name? require('@/assets/hyschool/tri-active.png'): require('@/assets/hyschool/tri.png')" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: ['list', 'activeTag', 'showTri'],
  data() {
    return {}
  },
  computed: {},
  methods: {
    choose(name) {
      this.$emit('tabChoose', name)
    },
  },
}
</script>

<style lang="less" scoped>
.tabs {
  display: flex;
  .tabs__item {
    cursor: pointer;
    font-size: 0.26rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-right: 1.32rem;
    display: flex;
  }
  .tabs__name {
    font-size: 0.26rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  .tabs__name--active {
    // color: #479ee3;
    color: #f47d1a;
  }
  .tabs__img {
    width: 0.16rem;
    height: 0.16rem;
    margin: 0.08rem 0 0 0.05rem;
  }
  .tabs__item--active {
    color: #479ee3;
    padding-bottom: 0.29rem;
    // border-bottom: 2px solid #479ee3;
    border-bottom: 2px solid #f47d1a;
  }
}
</style>